import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
//import { Features } from "./components/features2";
import { Preview } from "./components/preview";
import { Features } from "./components/features";

import { Space } from "./components/space";
import { ThreeD } from "./components/three-d";
import { Object } from "./components/object";
import { Dashboard } from "./components/dashboard";
import { Gallery } from "./components/gallery";
import { Footer } from "./components/footer";
import { Tutorial } from "./components/tutorial";

import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { RecoilRoot } from "recoil";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Contact } from "./components/contact";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
  offset: 80, // 추가적인 보정값, nav 사이즈가 80 이기에 (음수도 가능)
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState(JsonData);

  // useEffect(() => {
  //   setLandingPageData(JsonData);
  // }, [landingPageData]);

  return (
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                {/* 네비게이션바 */}
                <Navigation />
                {/* 가장위쪽, 영상 나오는곳 */}
                <Header data={landingPageData.Header} />
                {/* 프리뷰, 간단 설명 */}
                <Preview data={landingPageData.Preview} />
                {/* 모델릭 특징 */}
                <Features data={landingPageData.Features} />
                {/* 대시보드 */}
                <div style={{ backgroundColor: "#F8F8FA" }}>
                  <Dashboard data={landingPageData.Dashboard} />
                </div>
                {/* Modelic Space */}
                <div style={{ backgroundColor: "#F2F2F5" }}>
                  <Space data={landingPageData.ModelicSpace} />
                </div>
                {/* Modelic 3D */}
                <div style={{ backgroundColor: "#1F1F1F" }}>
                  <ThreeD data={landingPageData.Modelic3D} />
                </div>
                <Object data={landingPageData.ModelicSpace} />
                <Gallery data={landingPageData.Gallery} />
                <Tutorial data={landingPageData.Tutorial} />
                <Footer data={landingPageData.Contact} />
              </>
            }
          ></Route>
          <Route
            path="/contact"
            element={<Contact data={landingPageData.Contact} />}
          />
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  );
};

export default App;
