import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";

// 하위 컴포넌트
import React, { forwardRef, useImperativeHandle } from 'react';

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 100 : -100,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 100 : -100,
      opacity: 0
    };
  }
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

export const ImageSlider = forwardRef(({style, imgs, OnSlideCallback,onMouseDown}, ref) => {
  
  //초기화
  const customStyle = style || {
      width : '100%',
      height : 'auto',
      float : "center"
  };
  const images = imgs || [];


  const [[page, direction], setPage] = useState([0, 0]);

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, images.length, page);

  const paginate = (newDirection) => {

      setPage([imageIndex + newDirection, newDirection]);

      if(!OnSlideCallback)
        return;

      OnSlideCallback(page + 1);
  };

  // 부모로 노출할 함수를 설정
  useImperativeHandle(ref, () => ({
    setPage: setPage,
    page : page
  }));



  return (
      <>
  
          <AnimatePresence initial={false} custom={direction}>
              <motion.div
                  style={{
                      width: customStyle.width, // 슬라이드 영역 가로 크기
                      height: customStyle.height, // 슬라이드 영역 세로 크기
                      overflow: 'hidden', // 넘치는 부분은 숨김
                      position: 'relative', // 위치 지정
                      marginRight: "0"
                  }}>
          
                  <motion.img
                      key={page}
                      src={images[imageIndex]}
                      custom={direction}
                      variants={variants}                      
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{
                          x: { type: "spring", stiffness: 300, damping: 30 },
                          opacity: { duration: 0.2 }
                      }}
                      drag="x"
                      dragConstraints={{ left: 0, right: 0 }}
                      dragElastic={1}
                      style={{
                          width: customStyle.width, // 가로 크기
                          height: customStyle.height, // 세로 크기
                          overflowX: 'hidden',
                          float: customStyle.float,
                      }}
                      onDragEnd={(e, { offset, velocity }) => {
                          const swipe = swipePower(offset.x, velocity.x);

                          if (swipe < -swipeConfidenceThreshold) {
                          paginate(1);
                          } else if (swipe > swipeConfidenceThreshold) {
                          paginate(-1);
                          }
                      }}
                      onMouseDown={onMouseDown}
                      onTouchStart={onMouseDown}
                      />

              </motion.div>
          </AnimatePresence>                
      </>
  );
});
