import React from "react";
import { motion, Variants, AnimatePresence } from "framer-motion";
import { styled, css } from 'styled-components';
import { useRecoilValue } from "recoil";
import { SelectResolution } from "../recoil/selectors";
import ImageFlow from "./ui/ImageFlow"
import { object_images } from "./ui/image-data";
import {variableFont} from "../util/VariableFont"

const RootDiv = styled.div`
  font-family: Pretendard, sans-serif;    
  height: 1080px;
  max-height: 1080px;
  width: auto;
  background-color: #F2F2F5;
  padding: 0px;
  font-size : 16px;
  position: relative;  
  word-break: break-all;
  
  ${props => props.$resolution.width <= 991 && css`          
  height: 1060px;
  `}

  ${props => props.$resolution.width <= 600 && css`          
  height: 860px;
  `}
  ${props => props.$resolution.width <= 560 && css`          
  height: 660px;
  `}

`;

const SliderDiv = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  max-height: 1080px;
  display: flex;
  align-items: end;
  justify-content: center;
  overflow: hidden;
  transform: translateY(-32%);

  
  ${props => props.$resolution.width <= 600 && css`          
  transform: translateY(-16%);
  `}

  ${props => props.$resolution.width <= 560 && css`          
  transform: translateY(-2%);
  `}

  ${props => props.$resolution.width <= 425 && css`          
  transform: translateY(-30%);
  `}

  
`;

const BackgroudnDiv = styled.div`
  width: 100%;
  height: 70%;
  max-height: 1080px;
  position: absolute;
  display: flex;
  justify-content: center;
  
  margin-top: 374px;
 
  ${props => props.$resolution.width <= 425 && css`          
  margin-top: 200px;
  `}
`;

const TextAreaDiv = styled.div`
  margin-top: 108px;
  position: absolute;
  width: 100%;

  ${props => props.$resolution.width <= 991 && css`          
  margin-top: 40px;
  `}



`;

const TitleDiv = styled.div`
  font-size: 50px;
  width: 100%;
  height: auto;
  letter-spacing: -2.5px;
  line-height: 60px;
  color: #000000;
  font-weight: 600;
  text-align: center;

  ${props => props.$resolution.width <= 1024 && css`          
  font-size: 41px;
  line-height: 52px;    
  margin-top: 4px;
  `}

  ${props => props.$resolution.width <= 991 && css`          
  font-size: 58px;
  line-height: 72px;  
  `}

  ${props => props.$resolution.width <= 768 && css`          
  font-size: 54px;
  line-height: 66px;  
  `}

  ${props => props.$resolution.width <= 425 && css`          
  font-size: 40px;
  line-height: 48px;  
  margin-top: 8px;
  `}
    ${props => props.$resolution.width <= 375 && css`          
  font-size: 32px;
  line-height: 40px;  
  `}
`;

const ParagrapheDiv = styled.div`  
  font-size: 23px;
  letter-spacing: -1.2px;
  line-height: 29px;
  width: 100%;
  height: auto;
  color: #191919;
  font-weight: 400;
  text-align: center;
  margin-top: 13px;
  ${props => props.$resolution.width <= 1100 && css`          
  font-size: 20px;
  `}
`;


export function Object(props) {

  
  const resolution = useRecoilValue(SelectResolution);

  

  const cardVariants = {
    offscreen: {
      //x: -200,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 4
      },
    },
  };


  return (
    <RootDiv id="object" $resolution={resolution}>
      <TextAreaDiv $resolution={resolution}>
        <TitleDiv $resolution={resolution}>다양한 카테고리의 <br/>약 300여개 무료 오브젝트</TitleDiv>      
        <ParagrapheDiv $resolution={resolution}>무료 제공 오브젝트를 활용하여 나만의 공간을 꾸며보세요!</ParagrapheDiv>
      
      </TextAreaDiv>
      
      <SliderDiv $resolution={resolution}>
        <ImageFlow images={object_images} 
          style={{objectFit:"contain", maxWidth:"480px", width:"70%", height:"auto"}} 
          imgStyle={{ width:"100%", display: "flex"}}/>
          
      </SliderDiv>
      <BackgroudnDiv $resolution={resolution} >
        <img src="/img/object.png" style={{objectFit:"contain", maxWidth:"692px", maxHeight:"608px", width:"100%", height:"auto"}} alt="objmonitor"/>        
      </BackgroudnDiv>
    </RootDiv>
  );
}
