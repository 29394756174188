import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { styled, css } from "styled-components";
import { ImageSlider } from "./ui/ImageSlider";
import ImagePagination from "./ui/ImagePagination";

import { dashboard_images } from "./ui/image-data";
import { wrap } from "popmotion";

import { useRecoilValue } from "recoil";
import { SelectResolution } from "../recoil/selectors";
import { variableFont } from "../util/VariableFont";

// 컴포넌트 배경
const RootDiv = styled.div`
  font-family: Pretendard, sans-serif;
  height: min(100vw, 100vh);
  max-height: 1080px;
  width: auto;
  padding: 0px;
  max-width: 1920px;
  margin: 0 auto;
  word-break: break-all;

  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      height: 1060px;
    `}

  ${(props) =>
    props.$resolution.width <= 600 &&
    css`
      height: 860px;
    `}
  ${(props) =>
    props.$resolution.width <= 560 &&
    css`
      height: 660px;
    `}
`;

// 컴포넌트 내용
const ContentBox = styled.div`
  position: relative;
  height: 100%;
  padding: 0px;
`;

// content dashboard
const SubjectPComp = styled.p`
  font-weight: 600;
  font-size: 22px;
  color: #864edf;
  letter-spacing: 0px;
  line-height: 26px;
  margin: 0px;

  ${(props) =>
    props.$resolution.width <= 1024 &&
    css`
      font-size: 15px;
    `}

  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      font-size: 22px;
    `}

  ${(props) =>
    props.$resolution.width <= 768 &&
    css`
      font-size: 21px;
    `}

  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      font-size: 16px;
    `}
`;

// 타이틀
const TitlePComp = styled.p`
  font-size: 56px;
  color: #000000;
  font-weight: 600;
  letter-spacing: -2.8px;
  margin-bottom: 0px;
  line-height: 67px;
  white-space: pre-wrap;

  ${(props) =>
    props.$resolution.width <= 1024 &&
    css`
      font-size: 41px;
      line-height: 52px;
      margin-top: 4px;
    `}

  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      font-size: 58px;
      line-height: 72px;
    `}

  ${(props) =>
    props.$resolution.width <= 768 &&
    css`
      font-size: 54px;
      line-height: 66px;
    `}

  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      font-size: 40px;
      line-height: 48px;
      margin-top: 8px;
    `}



  ${(props) =>
    props.$resolution.width <= 375 &&
    css`
      font-size: 32px;
      line-height: 38px;
    `}
`;

// 내용
const ParagraphPComp = styled.p`
  font-size: 20px;
  color: #191919;
  font-weight: 400;
  letter-spacing: -1px;
  margin-bottom: 0px;
  width: auto;
  line-height: 30px;
  margin-top: 13px;
  white-space: pre-wrap;

  ${(props) =>
    props.$resolution.width <= 1024 &&
    css`
      font-size: 14px;
      line-height: 18px;
      margin-top: 8px;
    `}

  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      font-size: 20px;
      line-height: 25px;
      margin-top: 8px;
    `}

  ${(props) =>
    props.$resolution.width <= 768 &&
    css`
      font-size: 20px;
      letter-spacing: -1.3px;
      line-height: 24px;
    `}
  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      font-size: 16px;
      letter-spacing: -1.4px;
      line-height: 22px;
      margin-top: 14px;
    `}

  ${(props) =>
    props.$resolution.width <= 320 &&
    css`
      font-size: 14px;
      letter-spacing: -1.4px;
      line-height: 22px;
      margin-top: 8px;
    `}
`;

// 이미지
const ImgContentBox = styled.div`
  padding: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  ${(props) => props.$resolution.width <= 1660 && css``}

  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      bottom: 0px;
    `}
`;

// 페이징징
const PaginationBox = styled.div`
  position: absolute;
  bottom: 2%;
  right: 50%;
  transform: translateX(50%);
  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      right: 50%;
    `}
`;

// 이미지 슬라이드 박스
const SliderBox = styled.div`
  height: 100%;
  width: 100%;
`;

const SliderBottomBox = styled.div`
  position: absolute;
  padding-left: 5px;
  bottom: 50%;
  transform: translateY(59%);
  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      margin-bottom: 76px;
    `}

  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      margin-bottom: 46px;
    `}
`;

// 텍스트 영역
const TextContentArea = styled.div`
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 1000;

  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      height: 50%;
    `}

  ${(props) => props.$resolution.width <= 425 && css``}
`;

// 슬라이드
const SlideContentArea = styled.div`
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      height: 50%;
    `}
`;

// 컨테이너 애니메이션
const cardVariants = {
  offscreen: {
    //x: -200,
    opacity: 1,
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 4,
    },
  },
};

export function Dashboard(props) {
  const resolution = useRecoilValue(SelectResolution);
  const refImageSlider = useRef(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [timerId, setTimerId] = useState(0);

  // 페이지 넘기는거 관련인듯
  useEffect(() => {
    if (!props.data) return;

    if (timerId > 0) stopTimer();

    startTimer();

    refImageSlider.current.setPage([pageNumber, 1]);

    return () => {
      stopTimer();
    };
  }, [pageNumber]);

  const startTimer = () => {
    const id = setTimeout(() => {
      setPageNumber((prevPageNumber) => {
        const pageIndex = wrap(0, dashboard_images.length, prevPageNumber + 1);

        return pageIndex;
      });
    }, 5000);

    setTimerId(id);
  };

  const stopTimer = () => {
    clearTimeout(timerId);
  };

  const handlePageChange = (p) => {
    if (refImageSlider.current.page == p) return;

    if (refImageSlider.current.page > p) refImageSlider.current.setPage([p, 1]);
    else if (refImageSlider.current.page < p)
      refImageSlider.current.setPage([p, -1]);

    setPageNumber(p);
  };

  const handleMouseDown = (event) => {
    stopTimer();
  };

  const handleSlideCallback = (p) => {
    const pageIndex = wrap(0, dashboard_images.length, p);

    setPageNumber(pageIndex);
  };

  // 텍스트 설정
  const setTextContent = () => {
    let groupStype = {
      position: "absolute",
      bottom: "50%",
      transform: "translateY(50%)",
      width: "auto",
      left: "130px",
      marginRight: "20%",
    };

    if (resolution.width <= 1024 && resolution.width > 991) {
      groupStype = {
        position: "absolute",
        bottom: "50%",
        transform: "translateY(50%)",
        width: "auto",
        left: "65px",
        right: "65px",
      };
    } else if (resolution.width <= 991 && resolution.width > 768) {
      groupStype = {
        position: "absolute",
        bottom: "50%",
        transform: "translateY(50%)",
        width: "auto",
        left: "140px",
        right: "140px",
      };
    } else if (resolution.width <= 768 && resolution.width > 560) {
      groupStype = {
        position: "absolute",
        bottom: "50%",
        transform: "translateY(50%)",
        width: "auto",
        left: "110px",
        right: "110px",
      };
    } else if (resolution.width <= 560) {
      groupStype = {
        position: "absolute",
        width: "auto",
        left: "50px",
        right: "50px",
        marginTop: "50px",
      };
    }

    // 텍스트 내용 설정
    let textContent = (
      <div id="6" style={groupStype}>
        <div>
          <SubjectPComp $resolution={resolution}>
            {props.data ? props.data[pageNumber].subject : "loading..."}
          </SubjectPComp>
        </div>
        <motion.div
          key={`dash_d_text${pageNumber}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 2 }}
        >
          <div>
            <TitlePComp $resolution={resolution}>
              {props.data ? props.data[pageNumber].title : "loading..."}
            </TitlePComp>
          </div>
          <div>
            <ParagraphPComp $resolution={resolution}>
              {props.data ? props.data[pageNumber].paragraph : "loading..."}
            </ParagraphPComp>
          </div>
        </motion.div>
      </div>
    );

    return textContent;
  };

  const setImageSize = () => {
    let style = {
      width: "100%",
      height: "auto",
      float: "right",
    };

    if (resolution <= 425 && resolution > 320) {
      style = {
        width: "108%",
        height: "auto",
        float: "right",
      };
    } else if (resolution <= 320) {
      style = {
        width: "120%",
        height: "auto",
        float: "right",
      };
    }

    return style;
  };

  const setContent = () => {
    if (resolution <= 991) {
      return (
        <div style={{ position: "relative" }}>
          <TextContentArea id="5" className="col-md-5" $resolution={resolution}>
            {/*글자 영역*/}
            {setTextContent()}
          </TextContentArea>
          <SlideContentArea
            id="7"
            className="col-md-7"
            $resolution={resolution}
          >
            {/*슬라이드 영역*/}
            <SliderBox id="8" $resolution={resolution}>
              <SliderBottomBox id="9" $resolution={resolution}>
                <ImgContentBox id="10" $resolution={resolution}>
                  <ImageSlider
                    id="11"
                    style={setImageSize()}
                    imgs={dashboard_images}
                    OnSlideCallback={handleSlideCallback}
                    ref={refImageSlider}
                    onMouseDown={handleMouseDown}
                  ></ImageSlider>
                </ImgContentBox>
              </SliderBottomBox>
            </SliderBox>
          </SlideContentArea>
          {/* 페이지네이션 */}
          <PaginationBox id="2" $resolution={resolution}>
            <ImagePagination
              id="3"
              totalSlides={dashboard_images.length}
              activeSlide={pageNumber}
              onPageChange={handlePageChange}
            />
          </PaginationBox>
        </div>
      );
    } else {
      return (
        <>
          <SlideContentArea
            id="7"
            className="col-md-7"
            $resolution={resolution}
          >
            {/*슬라이드 영역*/}
            <SliderBox id="8" $resolution={resolution}>
              <SliderBottomBox id="9" $resolution={resolution}>
                <ImgContentBox id="10" $resolution={resolution}>
                  <ImageSlider
                    id="11"
                    style={setImageSize()}
                    imgs={dashboard_images}
                    OnSlideCallback={handleSlideCallback}
                    ref={refImageSlider}
                    onMouseDown={handleMouseDown}
                  ></ImageSlider>
                </ImgContentBox>
              </SliderBottomBox>
            </SliderBox>
          </SlideContentArea>
          <TextContentArea id="5" className="col-md-5" $resolution={resolution}>
            {/*글자 영역*/}
            {setTextContent()}
          </TextContentArea>
          {/* 페이지네이션 */}
          <PaginationBox id="2" $resolution={resolution}>
            <ImagePagination
              id="3"
              totalSlides={dashboard_images.length}
              activeSlide={pageNumber}
              onPageChange={handlePageChange}
            />
          </PaginationBox>
        </>
      );
    }
  };

  return (
    <RootDiv
      id="dashboard"
      className="container-fluid"
      $resolution={resolution}
    >
      <div id="1">
        <motion.div
          id="2"
          key="dashboard"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          variants={cardVariants}
        >
          <div id="3" className="row" style={{ margin: "0" }}>
            <ContentBox id="4" className="col-md-12" $resolution={resolution}>
              {setContent()}
            </ContentBox>
          </div>
        </motion.div>
      </div>
    </RootDiv>
  );
}
