import { useState } from "react";
import { styled, css } from "styled-components";
import { useRecoilValue } from "recoil";
import { SelectResolution } from "../recoil/selectors";

const RootDiv = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`;

const ContentDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 160px;
  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      height: 130px;
    `}
`;

const TitleDiv = styled.div`
  font-size: 36px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  letter-spacing: -1.8;
  line-height: 60px;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  line-hight: 43px;

  ${(props) =>
    props.$resolution.width <= 905 &&
    css`
      font-size: 28px;
    `}

  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      font-size: 24px;
      line-height: 30px;
    `}
`;

const ParagrapheDiv = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  letter-spacing: -1;
  line-height: 24px;
  color: #ffffff;
  ${(props) =>
    props.$resolution.width <= 1100 &&
    css`
      font-size: 17px;
    `}

  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      margin-top: 6px;
      font-size: 16px;
      line-height: 22px;
    `}
`;

const DownloadAtag = styled.a``;

const ImageTag = styled.img`
  width: 100%;
  height: 422px;
  object-fit: cover;
  object-position: left;

  ${(props) =>
    props.$resolution.width <= 768 &&
    css`
      height: 350px;
    `}
  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      height: 260px;
    `}
`;

const FooterContent = styled.div`
  position: relative;
  right: 2%;
  top: 34px;
  height: 150px;
  ${(props) =>
    props.$resolution.width <= 530 &&
    css`
      right: 20px;
    `}
`;

const FooterAddress = styled.p`
  font-size: 16px;
  letter-spacing: -0.42px;
  color: #767676;
  text-align: end;

  ${(props) =>
    props.$resolution.width <= 530 &&
    css`
      font-size: 15px;
    `}
  ${(props) =>
    props.$resolution.width <= 400 &&
    css`
      font-size: 12px;
    `}
`;

const FooterTel = styled.p`
  font-size: 16px;
  letter-spacing: -0.42px;
  color: #767676;
  text-align: end;
  ${(props) =>
    props.$resolution.width <= 530 &&
    css`
      font-size: 15px;
    `}
  ${(props) =>
    props.$resolution.width <= 400 &&
    css`
      font-size: 12px;
    `}
`;

const FooterCopyright = styled.p`
  font-size: 16px;
  letter-spacing: -0.42px;
  color: #767676;
  text-align: end;
  float: right;
  ${(props) =>
    props.$resolution.width <= 530 &&
    css`
      font-size: 15px;
    `}
  ${(props) =>
    props.$resolution.width <= 400 &&
    css`
      font-size: 12px;
    `}
`;

export const Footer = (props) => {
  const resolution = useRecoilValue(SelectResolution);

  return (
    <RootDiv id="contact_md" $resolution={resolution}>
      <div style={{ position: "relative", width: "100%" }}>
        <div>
          <ImageTag
            alt="footer_img"
            src="/img/footer.png"
            $resolution={resolution}
          />
        </div>
        <ContentDiv $resolution={resolution}>
          <TitleDiv $resolution={resolution}>
            모델릭에 대해서 더 궁금하신가요?
          </TitleDiv>
          <ParagrapheDiv $resolution={resolution}>
            지금 모델릭 소개서를 다운 받으면,
            <br />
            모델릭에 대해 더 자세히 알 수 있어요!
          </ParagrapheDiv>
          <div style={{ textAlign: "center", marginTop: "1%" }}>
            <a
              className="btn btn-custom"
              href="/contact"
              style={{ zIndex: "1", margin: "2px" }}
            >
              문의 하기
            </a>{" "}
            <a
              href="/asset/HNIX_3D저작도구 솔루션(Modelic) 소개서.pdf"
              download="HNIX_3D저작도구 솔루션(Modelic) 소개서"
              className="btn btn-custom btn-lg page-scroll mg-top24"
              style={{ zIndex: "1", margin: "2px" }}
            >
              소개서 다운로드
            </a>{" "}
          </div>
        </ContentDiv>
      </div>
      <FooterContent $resolution={resolution}>
        <div style={{ float: "right", top: "50%", left: "50%" }}>
          <FooterAddress $resolution={resolution}>
            본사 : 서울시 중구 퇴계로 272 아도라타워 4층
          </FooterAddress>
          <FooterTel $resolution={resolution}>
            Tel : 070-7204-9151 / Fax : 02-2274-2995
          </FooterTel>
          <br></br>
          <FooterCopyright $resolution={resolution}>
            COPYRIGHT ©2023 HNIX., LTD ALL RIGHTS RESERVED.
          </FooterCopyright>
        </div>
      </FooterContent>

      <div
        className="social"
        style={{ float: "right", marginRight: "4%", fontSize: 50 }}
      >
        <ul>
          <li>
            {/* <a href={props.data ? props.data.youtube : "/"}>
              <i className="fa fa-youtube"></i>                             
            </a> */}
          </li>
        </ul>
      </div>
    </RootDiv>
  );
};
