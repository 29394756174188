import React, { useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { color } from "framer-motion";
import styled from "styled-components";

const AgreementContentDivComp = styled.div`
  border: 1px solid #d7d5d5;
  padding: 12px;
  height: 216px;
  overflow-y: scroll;
`;

export const Contact = (props) => {
  const [serchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  // 초기 입력 상태
  const initialState = {
    title: "",
    email: "",
    message: "",
    agreement: "N",
  };

  // 입력 상태 설정
  const [{ title, email, message, agreement }, setState] =
    useState(initialState);

  // 입력 상태 변경
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCheckChange = (e) => {
    const { name, checked } = e.target;
    const value = checked ? "Y" : "N";
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  // 입력값 지우기
  const clearState = () => setState({ ...initialState });

  // 메일 전송
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const type = serchParams.get("type");
      const rs = await axios.post("/smtp/v1/inquire", {
        title: title,
        email: email,
        message: message,
        agree: agreement,
        type: type || "landing",
      });
      alert("메세지 전송 되었습니다.");
      if (type == "app") {
        navigate("/", { replace: false });
      }
    } catch (ex) {
      alert("메세지 전송에 실패 하였습니다.");
    }
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          {/* 문의하기 글 */}
          <div className="section-title">
            <h2>문의하기</h2>
            <p>
              아래 양식을 작성하여 보내 주시기 바랍니다. 해당 메세지는{" "}
              <span style={{ color: "#864EDF" }}>메일로 전송</span> 됩니다.
            </p>
          </div>
          <div className="col-md-8">
            <div className="row">
              {/* 메일 전송 폼 */}
              <form name="sentMessage" onSubmit={handleSubmit}>
                {/* 제목 & 이메일 */}
                <div className="row">
                  {/* 제목 입력 */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className="form-control"
                        placeholder="제목"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  {/* 이메일 입력 */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="이메일"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                {/* 본문 */}
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    placeholder="문의내용"
                    rows="4"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>

                {/* 개인정보 수집 동의 */}
                <div className="agreement-group">
                  <p className="title">개인정보수집동의</p>
                  <AgreementContentDivComp>
                    <p className="content">
                      {props.data ? props.data.agreement : "loading"}
                    </p>
                  </AgreementContentDivComp>

                  <div
                    style={{
                      marginTop: "14px",
                      display: "flex",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="agreement"
                      name="agreement"
                      required
                      onChange={handleCheckChange}
                    />
                    <label htmlFor="agreement">
                      위와 같이 개인정보를 수집·이용하는데 동의합니다.
                    </label>
                  </div>
                </div>

                {/* 전송 버튼 */}
                <button type="submit" className="btn btn-custom btn-lg">
                  등록하기
                </button>
              </form>
            </div>
          </div>

          {/* 컨택 정보 */}
          <div className="col-md-3 col-md-offset-1 contact-info">
            {/* 타이틀 및 주소 */}
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>

            {/* 전화번호 */}
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>

            {/* 이메일 */}
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
