import React from "react";
import { motion, Variants, AnimatePresence } from "framer-motion";
import { styled, css } from "styled-components";
import { useRecoilValue } from "recoil";
import { SelectResolution } from "../recoil/selectors";

// 이미지 컴포넌트
const ImgComp = styled.img`
  top: 0;
  left: 0;
  width: 100%;
  height: 855px;
  object-fit: cover;
  content: url(img/preview.png);
  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      height: 500px;
    `}
`;

// 타이틀 스타일 컴포넌트
const TitlePComp = styled.p`
  font-weight: 600;
  text-align: center;
  font-size: 50px;
  line-height: 60px;
  color: #fff;
  letter-spacing: -2.5px;
  margin-bottom: 0px;
  white-space: pre-wrap;
  margin-top: min(9vw, 90px);

  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      font-size: 30px;
      line-height: 34px;
    `}
`;

// 설명 스타일 컴포넌트
const ParagraphPComp = styled.p`
  font-weight: 400;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  letter-spacing: -1px;
  margin-bottom: 0px;
  white-space: pre-wrap;
  margin-top: 14px;

  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      font-size: 15px;
      line-height: 20px;
    `}
`;

export function Preview(props) {
  // 해상도 설정
  const resolution = useRecoilValue(SelectResolution);

  // 컴포넌트 애니메이션 설정
  const cardVariants = {
    // 안보일때 투명도 0
    offscreen: {
      //x: -200,
      opacity: 0,
    },
    // 보일때 애니메이션
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 4,
      },
    },
  };

  return (
    <div id="preview">
      <div className="container-fluid">
        <div className="row">
          {/* 컴포넌트의 애니메이션 설정 */}
          <motion.div
            key="dash"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.1 }}
            variants={cardVariants}
          >
            {/* 이미지, 내용 배치 */}
            <div style={{ position: "relative" }}>
              <ImgComp alt="" $resolution={resolution}></ImgComp>
              <div
                style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
              >
                <TitlePComp $resolution={resolution}>
                  {props.data ? props.data.title : "loading..."}
                </TitlePComp>
                <ParagraphPComp $resolution={resolution}>
                  {props.data
                    ? window.innerWidth <= 768
                      ? props.data.paragraph_m
                      : props.data.paragraph
                    : "loading..."}
                </ParagraphPComp>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
