import React, { useEffect, useState, useRef } from "react";

import { styled, css } from "styled-components";
import { useRecoilValue } from "recoil";
import { SelectResolution } from "../recoil/selectors";
import { variableFont } from "../util/VariableFont";

import modelicYoutubeData from "../data/modelic_youtube_link.json";

const RootDiv = styled.div`
  font-family: Pretendard, sans-serif;
  max-height: 774px;
  width: 100%;
  max-width: 1920px;
  padding: 0px;
  margin: 0 auto;
  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      height: 820px;
      max-height: 820px;
    `}
  ${(props) =>
    props.$resolution.width <= 768 &&
    css`
      height: 880px;
      max-height: 880px;
    `}
  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      height: 600px;
    `}
`;

const ContentBox = styled.div`
  position: relative;
  height: 100%;
  padding: 0px;
  max-height: 774;
`;

const SubjectPComp = styled.p`
  font-weight: 600;
  font-size: 22px;
  color: #864edf;
  letter-spacing: 0px;
  margin: 0px;

  ${(props) =>
    props.$resolution.width > 1024 &&
    css`
      font-size: ${variableFont(22, props.$resolution.width)};
    `}

  ${(props) =>
    props.$resolution.width <= 1024 &&
    css`
      font-size: 15px;
    `}

  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      font-size: 22px;
    `}

  ${(props) =>
    props.$resolution.width <= 768 &&
    css`
      font-size: 21px;
    `}

  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      font-size: 16px;
    `}
`;

const TitlePComp = styled.p`
  font-size: 56px;
  color: #000000;
  font-weight: 600;
  letter-spacing: -2.8px;
  margin-bottom: 0px;
  line-height: 67px;

  ${(props) =>
    props.$resolution.width <= 1024 &&
    css`
      font-size: 41px;
      line-height: 52px;
      margin-top: 4px;
    `}

  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      font-size: 58px;
      line-height: 72px;
    `}

  ${(props) =>
    props.$resolution.width <= 768 &&
    css`
      font-size: 54px;
      line-height: 66px;
    `}

  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      font-size: 40px;
      line-height: 48px;
      margin-top: 8px;
    `}



  ${(props) =>
    props.$resolution.width <= 375 &&
    css`
      font-size: 32px;
      line-height: 38px;
    `}
`;

const ParagraphPComp = styled.p`
  font-size: 20px;
  color: #191919;
  font-weight: 400;
  letter-spacing: -1px;
  margin-bottom: 0px;
  width: auto;
  line-height: 30px;
  margin-top: 13px;
  white-space: pre-wrap;

  ${(props) =>
    props.$resolution.width <= 1024 &&
    css`
      font-size: 14px;
      line-height: 18px;
      margin-top: 8px;
    `}

  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      font-size: 20px;
      line-height: 25px;
      margin-top: 8px;
    `}

  ${(props) =>
    props.$resolution.width <= 768 &&
    css`
      font-size: 20px;
      letter-spacing: -1.3px;
      line-height: 24px;
    `}
  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      font-size: 16px;
      letter-spacing: -1.4px;
      line-height: 22px;
      margin-top: 14px;
    `}

  ${(props) =>
    props.$resolution.width <= 320 &&
    css`
      font-size: 14px;
      letter-spacing: -1.4px;
      line-height: 22px;
      margin-top: 8px;
    `}
`;

const TextContentArea = styled.div`
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 1000;
  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      height: 50%;
    `}
`;

const PagingButton = styled.img`
  cursor: pointer;

  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      width: 30px;
      height: 30px;
    `}

  &.left {
    content: url(img/arrow_default_left.png);

    &:active {
      content: url(img/arrow_pressed_left.png);
    }
  }

  &.right {
    content: url(img/arrow_default_right.png);

    &:active {
      content: url(img/arrow_pressed_right.png);
    }
  }
`;

const VideoContentArea = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      height: 50%;
    `}
  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      height: 40%;
    `}
  ${(props) =>
    props.$resolution.width <= 375 &&
    css`
      height: 30%;
    `}
`;

const VideoBorder = styled.div`
  border-radius: 15px;
  overflow: hidden;
  aspect-ratio: 16/9;
  max-width: 768px;
  max-height: 432px;
  width: 100%;
`;

const ButtonArea = styled.div`
  position: absolute;
  bottom: 40%;
  left: 28%;

  a {
    margin: 2px;
    width: 250px;
    height: 52px;
  }

  ${(props) =>
    props.$resolution.width <= 1024 &&
    css`
      left: 32%;
    `}

  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      left: unset;
      display: flex;
      justify-content: center;
      bottom: 25%;
    `}
    ${(props) =>
    props.$resolution.width <= 768 &&
    css`
      bottom: 15%;
    `}
    ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      left: unset;
      display: flex;
      justify-content: center;
      bottom: 10%;
    `}
`;

export function Tutorial(props) {
  const resolution = useRecoilValue(SelectResolution);

  // 현재 유튜브 링크, 인덱스
  const linkList = Object.values(modelicYoutubeData);
  const [currentLinkIndex, setCurrentLinkIndex] = useState(0);

  useEffect(() => {}, []);

  const handlePrevious = () => {
    setCurrentLinkIndex(
      (prevIndex) => (prevIndex - 1 + linkList.length) % linkList.length
    );
  };

  const handleNext = () => {
    setCurrentLinkIndex((prevIndex) => (prevIndex + 1) % linkList.length);
  };

  const setTextContent = () => {
    let groupStype = {
      position: "absolute",
      bottom: "60%",
      height: "200px",
      transform: "translateY(50%)",
      width: "auto",
      left: "210px",
    };

    if (resolution.width <= 1024 && resolution.width > 991) {
      groupStype = {
        position: "absolute",
        bottom: "60%",
        height: "200px",
        transform: "translateY(50%)",
        width: "auto",
        left: "150px",
      };
    } else if (resolution.width <= 991 && resolution.width > 768) {
      groupStype = {
        position: "absolute",
        bottom: "60%",
        transform: "translateY(50%)",
        width: "auto",
        left: "140px",
        right: "140px",
      };
    } else if (resolution.width <= 768 && resolution.width > 560) {
      groupStype = {
        position: "absolute",
        bottom: "60%",
        transform: "translateY(50%)",
        width: "auto",
        left: "110px",
        right: "110px",
      };
    } else if (resolution.width <= 560) {
      groupStype = {
        position: "absolute",
        width: "auto",
        left: "50px",
        right: "50px",
        marginTop: "50px",
      };
    }

    let textContent = (
      <>
        <div id="6" style={groupStype}>
          <SubjectPComp $resolution={resolution}>
            {props.data ? props.data.subject : "loading..."}
          </SubjectPComp>
          <TitlePComp $resolution={resolution}>
            {props.data ? props.data.title : "loading..."}
          </TitlePComp>
          <ParagraphPComp $resolution={resolution}>
            {props.data ? props.data.paragraph : "loading..."}
          </ParagraphPComp>
        </div>
      </>
    );

    return textContent;
  };

  return (
    <RootDiv id="tutorial" className="container-fluid" $resolution={resolution}>
      <div id="3" className="row" style={{ margin: "0" }}>
        <ContentBox id="4" className="col-md-12" $resolution={resolution}>
          <TextContentArea id="5" className="col-md-5" $resolution={resolution}>
            {/* 글자 영역 */}
            {setTextContent()}

            <ButtonArea $resolution={resolution}>
              <a
                href="https://www.youtube.com/playlist?list=PLFMrKOFp38tB0uY5aIpj8n7YzzBoQrtQU"
                className="btn btn-custom2 btn-lg page-scroll"
              >
                <img src="img/youtube.png" alt="" />
              </a>{" "}
            </ButtonArea>
          </TextContentArea>

          <VideoContentArea $resolution={resolution}>
            <PagingButton
              $resolution={resolution}
              className="left"
              onClick={handlePrevious}
            />
            <VideoBorder $resolution={resolution}>
              <iframe
                style={{ width: "100%", height: "100%", border: "0" }}
                src={linkList[currentLinkIndex]}
                title="Modelic3D Tutorial"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen="1"
              />
            </VideoBorder>
            <PagingButton
              $resolution={resolution}
              className="right"
              onClick={handleNext}
            />
          </VideoContentArea>
        </ContentBox>
      </div>
    </RootDiv>
  );
}
