// selectors.js
import { selector } from "recoil";
import { Mobile } from "./atoms";
import { Resolution } from "./atoms";

export const SelectMobile = selector({
  key: "SelectMobile",
  get: ({ get }) => {
    return get(Mobile);
  }
});

export const SelectResolution = selector({
  key: "SelectResolution",
  get: ({ get }) => {
    return get(Resolution);
  }
});