import React, { useState, useEffect, useRef } from 'react';


const ImageFlow = ({ images, style, imgStyle }) => {
  const [visibleImages, setVisibleImages] = useState(images);
  const [translation, setTranslation] = useState(0);

  const [isTransitionEnabled, setIsTransitionEnabled] = useState(true);

  const [timerId, setTimerId] = useState(0);


  const myRef = useRef(null);

  const stopTimer = () => {
  
    clearTimeout(timerId);
    
  }

  const startTimer = () => {
    const animationTimeId = setTimeout(() => {

      clearTimeout(animationTimeId)

      if (document.visibilityState === "visible") {
        //이미지 우에서 좌로 이동       
        setTranslation((prevTranslation) => prevTranslation - myRef.current.offsetWidth); // 이미지의 가로 크기 + margin
      }

      startTimer();

    }, 3000); // 이미지가 한 번씩 이동하는 간격(ms)

    setTimerId(animationTimeId);
  }
 
  useEffect(() => {
    // 컴포넌트가 마운트된 후에 ref를 통해 DOM 요소에 접근
    const element = myRef.current;
    if (element) {
      const width = element.offsetWidth;
      const height = element.offsetHeight;
      

    }
  }, []);

  useEffect(() => {
    // 컴포넌트가 마운트될 때 초기에 타이머 시작
    startTimer();

    // 컴포넌트가 언마운트될 때 타이머 중지
    return () => {
      stopTimer();
    };
  }, []);


 
  const handleTransitionEnd = () => {
   
     // transition 활성화를 지연시키기 위한 작은 딜레이
    setIsTransitionEnabled(false);
    
    
    
    setVisibleImages((prevImages) => {
        const updatedImages = [...prevImages];
        const removedImage = updatedImages.shift();
        updatedImages.push(removedImage);
        return updatedImages;
      });
      
    
      setTranslation(0);
    // transition 활성화를 지연시키기 위한 작은 딜레이
    setTimeout(() => {
        setIsTransitionEnabled(true);
        
    }, 1000);


  };

  return (
    <div style={style} ref={myRef}>
      <div     
        className="image-flow-container"
        style={{
          transform: `translateX(${translation}px)`,
          transition: isTransitionEnabled ? 'transform 1s ease-in-out' : 'none',          
        }}        
        onTransitionEnd={handleTransitionEnd}
      >
        {visibleImages.map((image, index) => (
          
          <img key={index} style={imgStyle} className="flowing-image"  src={image} alt={`Flowing ${index + 1}`} />
        ))}
      </div>
    </div>
  );
};

export default ImageFlow;
