export const dashboard_images = [    
  "/img/dashboard.png"
];

export const images = [    
    "/img/space.png",
    "/img/batch.png",
];


export const three_d_images = [
  "/img/three_d_cube.png",
  "/img/three_d_user.png",
  
]


export const object_images = [
  "/img/object/obj_stick.png",
  "/img/object/obj_car.png",
  "/img/object/obj_basket.png",
  "/img/object/obj_ice.png",
  "/img/object/obj_tv.png",
  "/img/object/obj_tube.png",
  "/img/object/obj_bed.png"
];