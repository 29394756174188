import React from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { Resolution } from "../recoil/atoms";
import { Mobile } from "../recoil/atoms";
import { useEffect } from "react";

export const Header = (props) => {
  const [resolution, setResolution] = useRecoilState(Resolution);
  const [mobile, setIsMobile] = useRecoilState(Mobile);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      // console.log("width ::" + width);
      // console.log("height ::" + height);

      setResolution({
        width: width,
        height: height,
      });

      setIsMobile(width);
    };

    // 창 크기 조절 이벤트에 대한 이벤트 리스너 추가
    window.addEventListener("resize", handleResize);

    handleResize();
    // 컴포넌트가 언마운트될 때 이벤트 리스너 정리
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              {/* 영상 위 글자들 */}
              <div className="col-md-8 col-md-offset-2 intro-text">
                <p>{props.data ? props.data.subject : "Loading"}</p>
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                {/* 프리뷰 이동 버튼 */}
                <a
                  href="#preview"
                  className="btn btn-custom btn-lg page-scroll mg-top24"
                  style={{ zIndex: "1" }}
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* 영상 나오는 부분, muted autoPlay loop playsInline : 자동으로 재생되도록 */}
        <video id="video" muted autoPlay loop playsInline>
          {/* <source src="https://www.shutterstock.com/shutterstock/videos/1106217087/preview/stock-footage-abstract-dynamic-neon-light-frame-animation-background.webm" type="video/mp4" /> */}
          <source src="/videos/intro.mp4" type="video/mp4" />
        </video>
      </div>
    </header>
  );
};
