import React from 'react';

const ImagePagination = ({ totalSlides, activeSlide, onPageChange }) => {
  const pages = Array.from({ length: totalSlides }, (_, index) => index);



  const pageSource = (page) => {

    if(page == activeSlide)
      return '/img/circle_on.png';
    else
      return '/img/circle_off.png';

  };

  return (
    <div className="image-pagination">
      {pages.map((page) => (        
        <div
          key={page}          
          onClick={() => onPageChange(page)}
        >
          <img style={{width:"10px", height:"10px", marginLeft:"5px", marginRight:"10px"}} src={pageSource(page)} alt={`Page ${page}`} />
        </div>
      ))}
    </div>
  );
};

export default ImagePagination;
