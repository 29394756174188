import React from "react";
import { useRecoilValue } from "recoil";
import { SelectMobile } from "../recoil/selectors";
import { variableFont } from "../util/VariableFont";
import { styled, css } from "styled-components";

// styled-components - 네비게이션 크기 설정
const ContianerDiv = styled.div`
  padding-left: 60px;
  padding-right: 120px;

  ${(props) =>
    props.$ismobile <= 1620 &&
    css`
      padding-right: 60px;
    `}

  ${(props) =>
    props.$ismobile <= 760 &&
    css`
      padding-left: 40px;
      padding-right: 40px;
    `}

  ${(props) =>
    props.$ismobile <= 425 &&
    css`
      padding-left: 20px;
      padding-right: 10px;
    `}
`;

export const Navigation = (props) => {
  const isMobile = useRecoilValue(SelectMobile);

  const IconBar = () => {
    if (isMobile <= 425) {
      return (
        <>
          <span
            className="icon-bar"
            style={{
              backgroundColor: "#fff",
              height: "3px",
              width: "22px",
              marginBottom: "6px",
            }}
          ></span>
          <span
            className="icon-bar"
            style={{
              backgroundColor: "#fff",
              height: "3px",
              width: "30px",
              marginBottom: "6px",
            }}
          ></span>
          <span
            className="icon-bar"
            style={{ backgroundColor: "#fff", height: "3px", width: "14px" }}
          ></span>
        </>
      );
    } else {
      return (
        <>
          <span
            className="icon-bar"
            style={{
              backgroundColor: "#fff",
              height: "3px",
              width: "28px",
              marginBottom: "8px",
            }}
          ></span>
          <span
            className="icon-bar"
            style={{
              backgroundColor: "#fff",
              height: "3px",
              width: "36px",
              marginBottom: "8px",
            }}
          ></span>
          <span
            className="icon-bar"
            style={{ backgroundColor: "#fff", height: "3px", width: "20px" }}
          ></span>
        </>
      );
    }
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <ContianerDiv className="container-fluid" $ismobile={isMobile}>
        <div className="navbar-header">
          {/* 이 버튼은 뭐지?????? */}
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            style={{ borderWidth: "0px" }}
          >
            <span className="sr-only">Toggle navigation</span> {IconBar()}
          </button>
          {/* 이미지 */}
          <a className="navbar-brand page-scroll" href="#page-top">
            <img
              src="img/menu.png"
              style={{ marginTop: "2px", width: "120px", height: "auto" }}
            ></img>
          </a>
        </div>

        {/* 네비게이션 이동 */}
        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#preview" className="page-scroll">
                모델릭
              </a>
            </li>
            <li>
              <a href="#dashboard" className="page-scroll">
                모델릭 기능
              </a>
            </li>

            <li>
              <a href="#portfolio" className="page-scroll">
                활용사례
              </a>
            </li>
            <li>
              <a href="#tutorial" className="page-scroll">
                튜토리얼
              </a>
            </li>
            <li>
              <a href="#contact_md" className="page-scroll">
                문의 하기
              </a>
            </li>
          </ul>
        </div>
      </ContianerDiv>
    </nav>
  );
};
