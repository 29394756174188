// atoms.js
import { atom } from "recoil";

export const Mobile = atom({
  key: "Mobile", // 전역적으로 고유한 값
  default: 0 // 초기값
});

export const Resolution = atom({
  key: "Resolution", // 전역적으로 고유한 값
  default: {
    width : 0,
    height : 0
  } // 초기값
});