import { ImageComponent } from "./image";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { styled, css } from "styled-components";
import { useRecoilValue } from "recoil";
import { SelectResolution } from "../recoil/selectors";
import { variableFont } from "../util/VariableFont";

const RootDiv = styled.div`
  padding: 100px 0;
  background-color: #f8f8fa;
  ${(props) =>
    props.$resolution.width <= 600 &&
    css`
      padding: 300px 0;
    `}

  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      padding: 50px 0;
    `}
`;

const PagingButton = styled.img`
  cursor: pointer;

  &.left {
    content: url(img/arrow_default_left.png);

    &:active {
      content: url(img/arrow_pressed_left.png);
    }

    &.disabled {
      cursor: default;
      content: url(img/arrow_disabled_left.png);
    }
  }

  &.right {
    content: url(img/arrow_default_right.png);

    &:active {
      content: url(img/arrow_pressed_right.png);
    }

    &.disabled {
      cursor: default;
      content: url(img/arrow_disabled_right.png);
    }
  }
`;

const GalleryDiv = styled.div`
  padding: 5%;
  overflow: "hidden";
`;

const GalleryDisplay = styled.div`
  &.hidden {
    display: none;
  }
`;

const GalleryItem = styled.div`
  ${(props) =>
    props.$resolution.width <= 760 &&
    css`
      margin: 3%;
    `}
`;

const TitlePComp = styled.div`
  font-size: 50px;
  letter-spacing: -2.5px;
  line-height: 60px;
  color: #000000;
  font-weight: 600;
  text-align: center;

  ${(props) =>
    props.$resolution.width <= 1024 &&
    css`
      font-size: 41px;
      line-height: 52px;
    `}

  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      font-size: 58px;
      line-height: 72px;
    `}

  ${(props) =>
    props.$resolution.width <= 768 &&
    css`
      font-size: 54px;
      line-height: 66px;
    `}

  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      font-size: 40px;
      line-height: 48px;
    `}
  
  ${(props) =>
    props.$resolution.width <= 375 &&
    css`
      font-size: 32px;
      line-height: 40px;
    `}
`;

const ParagraphPComp = styled.p`
  font-size: 24px;
  letter-spacing: -1.2px;
  line-height: 29px;
  color: #191919;
  font-weight: 400;
  text-align: center;

  ${(props) =>
    props.$resolution.width <= 1024 &&
    css`
      font-size: 14px;
      line-height: 18px;
      margin-top: 8px;
    `}

  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      font-size: 20px;
      line-height: 25px;
      margin-top: 8px;
    `}

  ${(props) =>
    props.$resolution.width <= 768 &&
    css`
      font-size: 20px;
      letter-spacing: -1.3px;
      line-height: 24px;
    `}
  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      font-size: 16px;
      letter-spacing: -1.4px;
      line-height: 22px;
      margin-top: 14px;
    `}

  ${(props) =>
    props.$resolution.width <= 320 &&
    css`
      font-size: 14px;
      letter-spacing: -1.4px;
      line-height: 22px;
      margin-top: 8px;
    `}
`;

export const Gallery = (props) => {
  const resolution = useRecoilValue(SelectResolution);

  // 페이징
  const imageLimit = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = props.data ? Math.ceil(props.data.length / imageLimit) : 1;
  const offset = (currentPage - 1) * imageLimit;

  // 페이지 핸들링
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNextPage = () => {
    if (props.data && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <RootDiv id="portfolio" className="text-center" $resolution={resolution}>
      <div className="container">
        <div>
          <TitlePComp $resolution={resolution}>
            Modelic을 활용한 <br /> 다양한 콘텐츠
          </TitlePComp>
          <ParagraphPComp $resolution={resolution}>
            음악관, 영화관, 전시관, 교실,카페 등 원하는 형태의
            <br />
            다양한 3D 공간을 제작할 수 있습니다.
          </ParagraphPComp>
        </div>
        <div className="row">
          <div style={{ display: "flex", alignItems: "center" }}>
            <PagingButton
              className={`left ${currentPage === 1 ? "disabled" : ""}`}
              onClick={handlePrevPage}
            />
            <GalleryDiv>
              <GalleryDisplay className={currentPage !== 1 ? "hidden" : ""}>
                {props.data
                  ? props.data.slice(0, 6).map((d, i) => (
                      <GalleryItem
                        key={`${d.title}-${i}`}
                        className="col-sm-6 col-md-4 col-lg-4"
                        $resolution={resolution}
                      >
                        <ImageComponent
                          title={d.title}
                          largeImage={d.largeImage}
                          smallImage={d.smallImage}
                        />
                      </GalleryItem>
                    ))
                  : "Loading..."}
              </GalleryDisplay>
              <GalleryDisplay className={currentPage !== 2 ? "hidden" : ""}>
                {props.data
                  ? props.data.slice(6, 12).map((d, i) => (
                      <GalleryItem
                        key={`${d.title}-${i}`}
                        className="col-sm-6 col-md-4 col-lg-4"
                        $resolution={resolution}
                      >
                        <ImageComponent
                          title={d.title}
                          largeImage={d.largeImage}
                          smallImage={d.smallImage}
                        />
                      </GalleryItem>
                    ))
                  : "Loading..."}
              </GalleryDisplay>
            </GalleryDiv>

            <PagingButton
              className={`right ${
                currentPage === totalPages ? "disabled" : ""
              }`}
              onClick={handleNextPage}
            />
          </div>
        </div>
      </div>
    </RootDiv>
  );
};
