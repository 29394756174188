import React from "react";
import { motion, Variants, AnimatePresence } from "framer-motion";

import { styled, css } from "styled-components";
import { useRecoilValue } from "recoil";
import { SelectResolution } from "../recoil/selectors";

// 컴포넌트 배경
const RootDiv = styled.div`
  font-family: "Pretendard", sans-serif;
  height: 100vh;
  height: min(100vw, 100vh);
  max-height: 1080px;
  max-width: 1920px;
  margin: 0 auto;
  padding-top: 10vh;
  background-color: #fff;
  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      max-height: none;
      height: auto;
    `}
`;

// 반응형 컴포넌트
const RelativeDivComp = styled.div`
  position: relative;
  margin-left: 120px;
  margin-right: 120px;

  ${(props) =>
    props.$resolution.width <= 768 &&
    css`
      margin-left: 30px;
      margin-right: 30px;
    `}
  ${(props) =>
    props.$resolution.width <= 375 &&
    css`
      margin-left: 0px;
      margin-right: 0px;
    `}
`;

// 고정 컴포넌트
const AbsoluteDivComp = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
`;

// 타이틀 스타일 컴포넌트
const TitlePComp = styled.p`
  font-family: Pretendard SemiBold, sans-serif;
  text-align: center;
  font-size: 50px;
  line-height: 60px;
  color: #000;
  font-weight: 600;
  letter-spacing: -2.5px;
  margin-bottom: 0px;
  white-space: pre-wrap;

  ${(props) =>
    props.$resolution.width <= 768 &&
    css`
      font-size: 40px;
    `}

  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      font-size: 30px;
      line-height: 34px;
    `}
`;

// 설명 스타일 컴포넌트
const ParagraphPComp = styled.p`
  font-family: Pretendard Regular, sans-serif;
  text-align: center;
  font-size: 24px;
  line-height: 29px;
  color: #191919;
  font-weight: 400;
  letter-spacing: -1.2px;
  margin-bottom: 0px;
  white-space: pre-wrap;
  margin-top: 13px;
  ${(props) =>
    props.$resolution.width <= 768 &&
    css`
      margin-left: 30px;
      margin-right: 30px;
    `}

  ${(props) =>
    props.$resolution.width <= 425 &&
    css`
      font-size: 15px;
      line-height: 20px;
    `}
`;

// 이미지 제목 스타일 컴포넌트
const SubjectPComp = styled.p`
  font-family: Pretendard SemiBold, sans-serif;
  text-align: left;
  font-size: 26px;
  line-height: 31px;
  color: #111111;
  font-weight: 600;
  letter-spacing: -1.3px;
  margin-bottom: 0px;
  margin-top: 31px;
  margin-left: 0px;
  text-transform: capitalize;
  ${(props) =>
    props.$resolution.width <= 1280 &&
    css`
      margin-top: 20px;
      font-size: 20px;
      line-height: 24px;
    `}
`;

// 이미지 설명 스타일 컴포넌트
const DescriptionPComp = styled.p`
  font-family: Pretendard Regular, sans-serif;
  text-align: left;
  font-size: 20px;
  line-height: 24px;
  color: #767676;
  font-weight: 300;
  letter-spacing: -1px;
  margin-top: 6px;
  text-transform: capitalize;
  width: 100%;
  &::first-letter {
    margin-left: 0;
  }

  ${(props) =>
    props.$resolution.width <= 1280 &&
    css`
      font-size: 15px;
      line-height: 18px;
    `}

  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      margin-top: 13px;
      margin-bottom: 20vw;
    `}
`;

// 콘텐츠 - 콘텐츠간 간격
const ColDivComp = styled.div`
  margin: 0 auto;
  max-width: 532px;

  ${(props) =>
    props.$resolution.width <= 991 &&
    css`
      display: flex;
      justify-content: center;
    `}
`;

// 이미지 스타일
const ImgComp = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 100%;
`;

// 타이틀 - 첫번째 콘텐츠 사이 간격
const ContentComp = styled.div`
  margin-top: 70px;
  ${(props) =>
    props.$resolution.width <= 1098 &&
    css`
      margin-top: 15px;
    `}
`;

export function Features(props) {
  const resolution = useRecoilValue(SelectResolution);

  // 컴포넌트 애니메이션
  const cardVariants = {
    offscreen: {
      //x: 200,
      opacity: 0,
    },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 4,
      },
    },
  };

  // 요소가 보이는 범위 판단
  const getAmount = () => {
    if (resolution.width >= 991) return 0.8;

    return 0.2;
  };

  return (
    <RootDiv id="features" $resolution={resolution}>
      <div className=".container-fluid">
        <div>
          {/* 애니메이션 설정 */}
          <motion.div
            key="modelic3d"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: getAmount() }}
            variants={cardVariants}
          >
            {/* 반응형으로 감싸고 안쪽은 고정?? */}
            <RelativeDivComp $resolution={resolution}>
              <AbsoluteDivComp $resolution={resolution}>
                <TitlePComp className="title" $resolution={resolution}>
                  {props.data ? props.data.title : "loading..."}
                </TitlePComp>

                <ParagraphPComp $resolution={resolution}>
                  {props.data ? props.data.paragraph : "loading..."}
                </ParagraphPComp>

                {/* feature 리스트 */}
                <ContentComp $resolution={resolution}>
                  <ColDivComp className="col-md-4" $resolution={resolution}>
                    <div>
                      <ImgComp
                        src="img/dash-ui.png"
                        className="img-responsive"
                        alt=""
                        $resolution={resolution}
                      />
                      <SubjectPComp $resolution={resolution}>
                        {" "}
                        {props.data ? props.data.subject1 : "loding...."}{" "}
                      </SubjectPComp>
                      <DescriptionPComp $resolution={resolution}>
                        {" "}
                        {props.data
                          ? props.data.description1
                          : "loding...."}{" "}
                      </DescriptionPComp>
                    </div>
                  </ColDivComp>

                  <ColDivComp className="col-md-4" $resolution={resolution}>
                    <div>
                      <ImgComp
                        src="img/3d-ui.png"
                        className="img-responsive"
                        alt=""
                        $resolution={resolution}
                      />
                      <SubjectPComp $resolution={resolution}>
                        {" "}
                        {props.data ? props.data.subject2 : "loding...."}{" "}
                      </SubjectPComp>
                      <DescriptionPComp $resolution={resolution}>
                        {" "}
                        {props.data
                          ? props.data.description2
                          : "loding...."}{" "}
                      </DescriptionPComp>
                    </div>
                  </ColDivComp>

                  <ColDivComp className="col-md-4" $resolution={resolution}>
                    <div>
                      <ImgComp
                        src="img/space-ui.png"
                        className="img-responsive"
                        alt=""
                        $resolution={resolution}
                      />
                      <SubjectPComp $resolution={resolution}>
                        {" "}
                        {props.data ? props.data.subject3 : "loding...."}{" "}
                      </SubjectPComp>
                      <DescriptionPComp $resolution={resolution}>
                        {" "}
                        {props.data
                          ? props.data.description3
                          : "loding...."}{" "}
                      </DescriptionPComp>
                    </div>
                  </ColDivComp>
                </ContentComp>
              </AbsoluteDivComp>
            </RelativeDivComp>
          </motion.div>
        </div>
      </div>
    </RootDiv>
  );
}
